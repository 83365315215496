const truncate = require("html-truncate");

export const truncateText = (textTop: string|undefined, textMiddle: string| undefined, textBottom: string| undefined) => {
    if (!textTop && !textMiddle && !textBottom) return "";
    if (textTop && textTop?.length > 1000) {
      return truncate(textTop, 1000, { ellipsis: "" });
    }
    if (textMiddle && textMiddle?.length > 1000) {
      return truncate(textMiddle, 1000, { ellipsis: "" });
    }
    if (textBottom && textBottom?.length > 1000) {
      return truncate(textBottom, 1000, { ellipsis: "" });
    }
    return "";
  };