import React, { useContext, useEffect, useState, FC } from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "@reach/router";
import { getGoroskopLeadFormSentValue } from "@/account/utils";
import { isBrowser } from "@/utils/env";

import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import LeadForm from "../leadForm";
import { truncateText } from "./utils";
import { BlurredContentHocProps } from "./types";
import { PAYWALL_CLASS_NAME, PAYWALL_LD_JSON_OBJECT } from "./constants";
import "./styles.scss";

const BlurredContentHoc: FC<BlurredContentHocProps> = ({
  textBottom,
  textMiddle,
  textTop,
  fieldItems,
  textClassName,
  children,
}) => {
  const [shouldRenderContent, setShouldRenderContent] = useState(!isBrowser());
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const location = useLocation();
  const isSubscribed = getGoroskopLeadFormSentValue();

  const onAuthorizationLinkClick = () => {
    setAuthParamToURL(location, FormTypeEnum.Login);
  };

  const truncatedText = truncateText(textTop, textMiddle, textBottom);

  useEffect(() => {
    if (isUserLoggedIn || !truncatedText || isSubscribed) setShouldRenderContent(true);
  }, [isSubscribed, isUserLoggedIn, truncatedText]);



  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(PAYWALL_LD_JSON_OBJECT)}</script>
      </Helmet>
      {!shouldRenderContent ? (
        <>
          <div className="parent-blurred">
            <div
              className={textClassName}
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(truncatedText),
              }}
            />
            <div className="blurred-section" />
          </div>
          <div className="blurred-content__lead">
            <LeadForm
              title="Подпишитесь, чтобы дочитать статью"
              description="<p>На Lunaro каждый день выходят новые статьи про Таро и эзотерику. Мы постоянно
                      изучаем новые расклады, колоды, общаемся c экспертами — и пишем про это. Это
                      отличный источник знаний. Бесплатно.<br><b>2 391 подписчик</b> вряд ли могут
                      ошибаться 😉</p>"
              formId="signed_blur"
              metricsId="signed_blur"
              fieldItems={fieldItems}
              buttonText="Подписаться"
              successTitle="Значения Арканов: письма тем, кто изучает Таро"
              successDescription="Статьи о том, как трактовать карты, какие расклады бывают, и какие книги по Таро самые авторитетные. В вашей почте раз в неделю. Бесплатно"
              onButtonClickAddition={() => {
                setShouldRenderContent(true);
              }}
            />
            <div className="blurred-content__lead--subscribe">
              <p>
                Уже подписаны? <a onClick={onAuthorizationLinkClick}>Тогда войдите</a>
              </p>
            </div>
          </div>
        </>
      ) : <div className={PAYWALL_CLASS_NAME}>{children}</div>}
    </>
  );
};

export default BlurredContentHoc;
